import React, { useEffect } from 'react'
import Lottie from 'react-lottie'
import successAnimationData from '../assets/lotties/success.json'
import styled from '@emotion/styled'
import { Button as ButtonDefault } from '../components'
import { Modal } from 'antd'
import { useCursoAtual } from '../cursos/CursosStore'
import { useHistory } from 'react-router'
import axios from 'axios'
declare var fbq: any

interface HomeParams {
  cursoId: string
}

export function ConfirmacaoMatricula() {
  const history = useHistory()
  const { Container, Button } = ConfirmacaoMatricula
  const urlSearchParams = new URLSearchParams(document.location.search.slice(1))
  const cursoId = urlSearchParams.get('cursoId') ?? 9999
  const curso = useCursoAtual({ cursoId: +cursoId! })
  if (!curso) location.href = '/seleciona-curso'

  useEffect(() => {
    sendTrack()
  }, [])

  const sendTrack = async () => {
    try {
      const matricula = await axios.get(`/matriculas/curso/${cursoId}/self`).then((res) => res.data)
      if (matricula?.sendTrack) {
        return (location.href = '/seleciona-curso')
      }
      await axios.put('/matriculas/sendTrack')
    } catch (error) {}
  }

  const closeModal = () => {
    history.push(`/curso/${cursoId}/home`)
  }

  return (
    <Container>
      <h1>
        <strong>Matrícula efetuada com sucesso!</strong>
      </h1>

      <h2>{curso!.nome}</h2>
      <Lottie
        style={{ width: 194, height: 194 }}
        options={{
          loop: false,
          autoplay: true,
          animationData: successAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />

      <h2>
        Você tem acesso <strong>total</strong> à plataforma New Driver.
      </h2>

      <p>
        <em>Em caso de dúvidas, entre em contato com nossos tutores.</em>
      </p>

      <Button label="Fechar" style={{ margin: '0px auto' }} onClick={closeModal} />
    </Container>
  )
}

ConfirmacaoMatricula.Container = styled.div`
  box-shadow: 0px -1px 16px rgba(51, 51, 51, 0.356);
  border-radius: 7px;
  padding: 10px;
  margin: 10px auto;
  margin-bottom: 30px;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  background: white;
  @media (max-width: 1048px) {
    box-shadow: none;
  }
`
ConfirmacaoMatricula.Button = styled(ButtonDefault)`
  @media (min-width: 458px) {
    height: 55px;
    min-width: 200px;
    border-radius: 8px;
    .label {
      font-weight: bold;
      font-size: 18px;
    }
  }
`

const ModalConfirmacaoMatricula = ({
  children,
  open,
}: {
  children: React.ReactNode
  open: boolean
}) => {
  const { Modal } = ModalConfirmacaoMatricula

  return (
    <Modal open={open} closable={false} footer>
      {children}
    </Modal>
  )
}

ModalConfirmacaoMatricula.Modal = styled(Modal)`
  max-width: 777px;
  @media (max-width: 801px) {
    max-width: calc(100vw - 24px);
  }
`
