import React, { useEffect } from 'react'
import Lottie from 'react-lottie'
import successAnimationData from '../assets/lotties/success.json'
import styled from '@emotion/styled'
import { Button } from './MatriculasComponents'
import { ReactComponent as Logo } from '../assets/matricula/logo.svg'
declare var fbq: any

export function SucessoMatricula() {
  const { Layout, Container } = SucessoMatricula

  const urlSearchParams = new URLSearchParams(document.location.search.slice(1))
  const curso = urlSearchParams.get('curso')
  const meioPagamento = urlSearchParams.get('meiopagamento')

  return (
    <Layout>
      <Container>
        <Logo />
        {!meioPagamento || meioPagamento === 'boleto' ? (
          <h1>
            <strong>Matrícula efetuada com sucesso!</strong>
          </h1>
        ) : (
          <h1>
            <strong>Matrícula e pagamento efetuados com sucesso!</strong>
          </h1>
        )}
        <h2>{curso}</h2>
        <Lottie
          style={{ width: 194, height: 194 }}
          options={{
            loop: false,
            autoplay: true,
            animationData: successAnimationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
        {meioPagamento === 'boleto' ? (
          <div className="boleto-msg">
            <h2>
              Você receberá um e-mail com <strong>ACESSO LIMITADO</strong> à plataforma New Driver.
            </h2>
          </div>
        ) : (
          <h2>
            Você receberá um e-mail com <strong>ACESSO IMEDIATO</strong> à plataforma New Driver.
          </h2>
        )}
        <p>
          <em>
            Não se esqueça de verificar a pasta de spam. Em caso de dúvidas, entre em contato com
            nossos tutores.
          </em>
        </p>
        <Button
          label="Iniciar o seu curso"
          style={{ margin: '0px auto' }}
          onClick={() => {
            window.location.href = 'https://app.newdriver.com.br'
          }}
        />
        <Button
          label="Ver mais cursos"
          style={{ margin: '0px auto' }}
          onClick={() => {
            window.location.href = 'https://newdriver.com.br/#one'
          }}
        />
        <br />
      </Container>
    </Layout>
  )
}
SucessoMatricula.Layout = styled.div`
  background: #f2f2f2;
  min-height: 100vh;
  display: grid;
  place-items: center;
  @media (max-width: 1048px) {
    background: white;
  }

  h1 {
    font-size: 20px;
    line-height: 36px;
    color: #000000;
  }

  h2 {
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #333333;
  }

  p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;

    /* Gray 1 */
    color: #333333;
  }

  .boleto-msg {
    background: #ffebb2;
    padding: 10px;
    margin: 0px 10%;
  }
`
SucessoMatricula.Container = styled.div`
  box-shadow: 0px -1px 16px rgba(51, 51, 51, 0.356);
  border-radius: 7px;
  padding: 10px;
  margin: 10px auto;
  margin-bottom: 30px;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  background: white;
  @media (max-width: 1048px) {
    box-shadow: none;
  }
`
