import { format, parseISO } from 'date-fns'

export const formatDate = (data: any, dateFormat?: string) =>
  format(parseISO(data), dateFormat ?? 'dd/MM/yyyy')

export const formatMoeda = (valor: any) => {
  if (isNaN(valor)) return null
  if (!valor || valor !== 0) return valor
  return valor.toFixed(2).replace('.', ',')
}
