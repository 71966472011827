import { IonIcon, IonPage, IonRippleEffect, useIonViewDidEnter } from '@ionic/react'
import { useMedia } from 'use-media'
import { useParams, useHistory } from 'react-router'
import { videocam } from 'ionicons/icons'
import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'

import styled from '@emotion/styled'

import { AppAvatar } from '../components/AppAvatar'
import { CardFoto, CardMedio, CardSimples, MobileCardContainer } from './Cards'
import Card from '../components/Card'
import iconeCirculoExclamacao from '../assets/icons/exclamation-circle.svg'
import LegislacaoDefensiva from '../assets/imagens/direcao-defensiva.jpg'
import iconeForumQuestao from '../assets/icons/comment-question.svg'
import iconeLivro from '../assets/icons/book.svg'
import iconeMensagem from '../assets/icons/mail.svg'
import iconeResposta from '../assets/icons/reply.svg'
import iconeTrofeu from '../assets/icons/cup.svg'
import iconeRelogio from '../assets/icons/clock.svg'
import { Button, TimelineItem, TituloPagina } from '../components'
import { ModuloWithEstatisticaEstudo } from '../models/ModuloModel'
import { MatriculaModel, STATUS_MATRICULA } from '../models/MatriculaModel'
import Svgs from '../svgs'
import { formatDistance, parseISO, formatISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { ForumModel } from '../models/ForumModel'
import { useSelector, useDispatch } from 'react-redux'
import { getModulosComEstatisticas, modulosActions } from '../modulos/ModulosStore'
import { progressoActions } from '../conteudo/ProgressoReduxStore'
import { Show } from '../common/Show'
import { useCursoAtual } from '../cursos/CursosStore'
import { CursoModel } from '../models/CursoModel'
import { useOpenJivoChat } from '../common/hooks/useJivoApi'
import { getUsuarioLogado } from '../auth/AuthStore'
import { ConfirmacaoMatricula } from './ConfirmacaoMatricula'
import { AxiosError } from 'axios'

const MENSAGENS = [
  {
    titulo: 'Seja bem vindo',
    tutor: 'Nome do tutor',
    pastTime: '2 dias atrás',
    conteudo:
      'Peço que olhem constantemente o painel de mensagens, pois encaminharemos mensagens ao...',
  },
  {
    titulo: 'Nova atividade',
    tutor: 'Bruno Rodrigues',
    pastTime: '1 dias atrás',
    conteudo:
      'Peço que olhem constantemente o painel de mensagens, pois encaminharemos mensagens ao...',
  },
]

const PERGUNTAS = [
  {
    titulo: 'Titulo da pergunta',
    conteudo:
      'Peço que olhem constantemente o painel de mensagens, pois encaminharemos mensagens ao...',
  },
  {
    titulo: 'Titulo da pergunta',
    conteudo:
      'Peço que olhem constantemente o painel de mensagens, pois encaminharemos mensagens ao...',
  },
]

const AULAS = [
  {
    titulo: 'Legislação de trânsito 1',
    modulo: 'Legislação de trânsito',
    professsor: 'Prof. Jhon Doe',
    horario: '10:50h',
  },
  {
    titulo: 'Legislação de trânsito 2',
    modulo: 'Legislação de trânsito',
    professsor: 'PRof. Jhon Doe',
    horario: '10:50h',
  },
  {
    titulo: 'Legislação de trânsito 3',
    modulo: 'Legislação de trânsito',
    professsor: 'PRof. Jhon Doe',
    horario: '10:50h',
  },
  {
    titulo: 'Legislação de trânsito 4',
    modulo: 'Legislação de trânsito',
    professsor: 'PRof. Jhon Doe',
    horario: '10:50h',
  },
]

const AVISOS = (curso: CursoModel) => [
  {
    id: 4,
    visualizado: false,
    texto: 'Ao final do curso você deverá agendar sua prova no DETRAN',
    createdAt: formatISO(new Date()),
  },
  {
    id: 1,
    visualizado: false,
    texto: `Você pode estudar no máximo 8h por dia para concluir o curso de ${curso.nome}.`,
    createdAt: '2021-01-10T14:00:56.328Z',
  },
  {
    id: 2,
    visualizado: false,
    texto: `Você deve estudar no mínimo ${curso?.diasParaEmissaoCertificado} dias para conseguir concluir o curso!`,
    createdAt: '2021-01-09T10:32:56.328Z',
  },
  {
    id: 3,
    visualizado: false,
    texto: `Bem-vindo ao curso de ${curso.nome}, nosso time de tutores está disponível para tirar qualquer dúvida que você tiver.
Bons estudos!`,
    createdAt: '2020-11-12T14:32:56.328Z',
  },
]

const CardNumero = styled(CardSimples)`
  /* margin-bottom: 10px; */
`

const CardNotificacao = styled(CardMedio)``

// const TituloPagina = styled('span')`
//   margin-bottom: 9px;
//   margin-left: 9px;
//   font-size: 24px;
//   font-weight: 700;
//   color: #2D2D2D;
// `

interface HomeParams {
  cursoId: string
}

const Home = ({ className }) => {
  const { cursoId } = useParams<HomeParams>()
  const [{ data: dashboard, loading }, fetchDashboard] = useAxios(`dashboard/curso/${cursoId}`)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(modulosActions.loadModulos(+cursoId))
    dispatch(progressoActions.loadEstatisticasEstudo(+cursoId))
  }, [])

  const isWide = useMedia({ minWidth: '769px' })

  if (loading || !dashboard) {
    return <div> carregando </div>
  }

  // if (isWide) {
  return <HomeDesktop cursoId={cursoId} dashboard={dashboard} />
  // }

  // return <HomeMobile cursoId={cursoId} dashboard={dashboard} />
}

const HomeMobile = (props) => {
  const { dashboard, cursoId } = props
  const { Layout, LayoutTop, LayoutBottom } = HomeMobile

  return (
    <Layout>
      <LayoutTop>
        {/* <DiasRestantes quantidade={dashboard.quantidadeDiasRestantes} /> */}
        <ExerciciosFeitos quantidade={dashboard.quantidadeExerciciosConcluidos} />
        <ModulosConcluidos />
      </LayoutTop>
      <LayoutBottom>
        <MobileCardContainer titulo="Módulo em andamento">
          <div style={{ marginTop: '20px' }}>
            <ModuloEmAndamento />
          </div>
        </MobileCardContainer>
        <MobileCardContainer icone={iconeCirculoExclamacao} color="#F06F30" titulo="Avisos">
          <Avisos cursoId={cursoId} />
        </MobileCardContainer>
        <MobileCardContainer icone={iconeMensagem} color="#F06F30" titulo="Mensagens">
          <Mensagens />
        </MobileCardContainer>
        <MobileCardContainer icone={iconeForumQuestao} color="#F06F30" titulo="Fórum de discussões">
          <ForumDiscussoes foruns={dashboard.foruns.rows.slice(0, 2)} />
        </MobileCardContainer>
      </LayoutBottom>
    </Layout>
  )
}

HomeMobile.Layout = styled('div')`
  margin-top: 8px 6px;
  display: grid;
  grid-template-rows: 2;
`

HomeMobile.LayoutTop = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, auto);
`

HomeMobile.LayoutBottom = styled('div')`
  display: grid;
  grid-template-columns: auto;
`

const HomeDesktop = (props) => {
  const { dashboard, cursoId } = props
  const curso = useCursoAtual({ cursoId })
  const { Layout, LayoutTop, LayoutBottom } = HomeDesktop
  return (
    <Layout {...props}>
      <TituloPagina>{curso!.nome}</TituloPagina>
      <LayoutTop>
        <DiasRestantes className="dias-restantes" quantidade={dashboard.quantidadeDiasRestantes} />
        <ExerciciosFeitos
          className="exercicios-feitos"
          quantidade={dashboard.quantidadeExerciciosConcluidos}
        />
        <ModulosConcluidos className="modulos-concluidos" />
      </LayoutTop>
      <LayoutBottom>
        <ModuloEmAndamento className="modulo-em-andamento" />
        <ForumDiscussoes
          className="forum-discussoes"
          foruns={dashboard.foruns?.rows?.slice(0, 2) ?? []}
        />
        <Avisos cursoId={cursoId} className="avisos" />
      </LayoutBottom>
    </Layout>
  )
}

HomeDesktop.Layout = styled('div')`
  display: grid;
  grid-template-rows: 2;
  @media (max-width: 1023px) {
    max-width: 575px;
    margin: 0 auto;
  }
  @media (max-width: calc(575px + 24px)) {
    margin: 0 12px;
  }
`

HomeDesktop.LayoutTop = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 18px;
  .dias-restantes {
    grid-area: dias-restantes;
  }
  .exercicios-feitos {
    grid-area: exercicios-feitos;
  }
  .modulos-concluidos {
    grid-area: modulos-concluidos;
  }
  grid-template-areas: 'dias-restantes exercicios-feitos modulos-concluidos';
  margin-top: 18px;
  @media (max-width: 1023px) {
    gap: 11px;
    margin-top: 11px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'modulos-concluidos exercicios-feitos'
      'dias-restantes dias-restantes';
  }
`

HomeDesktop.LayoutBottom = styled('div')`
  display: grid;
  gap: 28px;
  margin-top: 28px;
  @media (max-width: 768px) {
    margin-top: 11px;
  }
  @media (max-width: 1023px) {
    grid-template-areas:
      'modulo-em-andamento'
      'avisos'
      'forum-discussoes';
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'modulo-em-andamento avisos'
      'forum-discussoes avisos';
  }
  .modulo-em-andamento {
    grid-area: modulo-em-andamento;
  }
  .forum-discussoes {
    grid-area: forum-discussoes;
  }
  .avisos {
    grid-area: avisos;
  }
  /* @media (min-width: 1250px) {
    grid-template-columns: repeat(3, max-content);
  }
  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, max-content);
  } */
`

const DiasRestantes = ({
  quantidade,
  ...props
}: { quantidade: number } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <CardNumero
      icone={iconeRelogio}
      titulo={quantidade}
      label="Dias para o curso expirar"
      iconeColor="#EB5757"
      iconeBackground="#EB575707"
      {...props}
    />
  )
}

const ExerciciosFeitos = ({
  quantidade,
  ...props
}: { quantidade: number } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <CardNumero
      icone={iconeTrofeu}
      titulo={quantidade}
      label="Exercícios feitos"
      iconeColor="#FFB800"
      iconeBackground="rgba(255, 184, 0, 0.07)"
      {...props}
    />
  )
}

const ModulosConcluidos = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const modulos = useSelector(getModulosComEstatisticas)

  const quantidade = modulos.filter((m) => m.estatisticaEstudo?.moduloCompleto).length

  return (
    <CardNumero
      icone={iconeLivro}
      titulo={quantidade}
      label="Módulos concluídos"
      iconeColor="#4CB8FF"
      iconeBackground="rgba(76, 184, 255, 0.07)"
      {...props}
    />
  )
}

const Exercicios = ({ quantidade }) => {
  return <CardNumero icone={iconeTrofeu} titulo={quantidade} label="Exercicios Entregues" />
}

const ModuloEmAndamento = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { getStatusCurso, Card, Button, Titulo, PorcentagemConclusao, Status } = ModuloEmAndamento
  const { cursoId } = useParams<HomeParams>()

  const [{ data: matricula }, refetchMatricula] = useAxios(`/matriculas/curso/${cursoId}/self`, {
    manual: true,
  })
  React.useEffect(() => {
    refetchMatricula()
  }, [])
  const modulos = useSelector(getModulosComEstatisticas)
  const history = useHistory()
  const dispatch = useDispatch()

  const { moduloAtual, status } = getStatusCurso(modulos, matricula)
  const progresso = moduloAtual?.estatisticaEstudo?.percentualProgressoMaisAvancado?.toFixed() ?? 0

  function navigate(destination) {
    history.push(`/curso/${cursoId}/${destination}`)
  }

  const handleClickContinuar = () => history.push(`/curso/${cursoId}/conteudo/${moduloAtual?.id}`)
  const handleClickIniciar = () => history.push(`/curso/${cursoId}/conteudo/${moduloAtual?.id}`)
  const handleClickAvaliacao = () =>
    history.push(`/curso/${cursoId}/regras-avaliacao/${moduloAtual?.id}`)
  const handleClickRevisarConteudo = () => handleClickContinuar()
  const handleClickAgendarProvaDetran = () =>
    window.open('https://www.detran.pe.gov.br/prova-teorica-de-reciclagem')
  const handleClickValidacaoFacial = () => history.push(`/curso/${cursoId}/validacao-facial/final`)
  const handleRefazerAvaliacao = () => {
    if (!moduloAtual) return
    dispatch(
      modulosActions.refazerAvaliacao(moduloAtual.id, () => {
        dispatch(modulosActions.loadModulos(+cursoId))
        dispatch(progressoActions.loadEstatisticasEstudo(+cursoId))
        navigate(`regras-avaliacao/${moduloAtual.id}`)
      })
    )
  }

  if (status === 'AGUARDANDO_CURSO_PRATICO') {
    return (
      <div>
        <Card color="#2F80ED" {...props}>
          <Titulo>Aguardando curso prático!</Titulo>
          <p>
            Parabéns! Você concluiu o módulo teórico e agora está aguardando a matrícula e a
            conclusão do curso prático.
          </p>
          <p>
            Lembre-se de se matricular na aula prática para continuar sua jornada de aprendizado.
          </p>
        </Card>
      </div>
    )
  }

  if (status === 'AGUARDANDO_LIBERACAO_CERTIFICADO') {
    return (
      <div>
        <Card color="#2F80ED" {...props}>
          <Titulo>Não foi possível emitir o certificado!</Titulo>
          <p>Por favor, entre em contato com o suporte!</p>
        </Card>
      </div>
    )
  }

  if (status === 'AGUARDANDO_VALIDACAO_FACIAL_FINAL') {
    return (
      <div>
        <Card color="#2F80ED" {...props}>
          <Titulo>Validação facial pendente</Titulo>
          <p>Você só precisa efetuar uma última validação facial antes de concluir seu curso</p>
          <Button label="Efetuar validação facial" onClick={handleClickValidacaoFacial} />
        </Card>
      </div>
    )
  }

  if (status === 'CURSO_CONCLUIDO' || status === 'AGUARDANDO_ENVIO_CERTIFICADO') {
    return (
      <div>
        <Card color="#2F80ED" {...props}>
          <Titulo>Parabéns! Curso concluído</Titulo>
          {status === 'CURSO_CONCLUIDO' ? (
            <Certificado matricula={matricula} />
          ) : (
            <AguardandoEnvioCertificado matricula={matricula} />
          )}
        </Card>
      </div>
    )
  }

  if (matricula?.statusDetran === 'MATRICULADO' && !matricula?.sendTrack) {
    setTimeout(() => {
      history.push(`/bemvindo?cursoId=${matricula.cursoId}`)
    }, 2000)
  }

  return (
    <div>
      <Card color="#2F80ED" {...props}>
        <Titulo>{moduloAtual?.nome}</Titulo>
        <PorcentagemConclusao>
          <Svgs.Clock color="#F06F30" width={15} style={{ marginRight: 10 }} />
          {progresso}% concluído
        </PorcentagemConclusao>
        <Show condition={status === 'MODULO_INICIADO'}>
          <Button
            svg={<Svgs.Play color="white" />}
            label="Continuar estudando"
            svgPosition="LEFT"
            onClick={handleClickContinuar}
          />
        </Show>
        <Show condition={status === 'MODULO_NAO_INICIADO'}>
          <Button
            svg={<Svgs.Play color="white" />}
            label="Começar a estudar"
            svgPosition="LEFT"
            onClick={handleClickIniciar}
          />
        </Show>
        <Show condition={status === 'AGUARDANDO_AVALIACAO'}>
          <Button
            svg={<Svgs.Play color="white" />}
            label="Fazer a avaliação"
            color="#2F80ED"
            svgPosition="LEFT"
            onClick={handleClickAvaliacao}
          />

          <Button
            label="Revisar conteúdo"
            onClick={handleClickRevisarConteudo}
            fill="OUTLINE"
            color="#2F80ED"
            style={{ marginBottom: 24 }}
          />
        </Show>
        <Show condition={status === 'REFAZER_AVALIACAO'}>
          <div style={{ margin: '18px 0px' }}>
            Status:
            <Status style={{ backgroundColor: 'rgb(255 0 0 / 29%)', color: '#ff0000' }}>
              Reprovado
            </Status>
          </div>
          <Button
            svg={<Svgs.Play color="white" />}
            label="Refazer Avaliação"
            color="#2F80ED"
            svgPosition="LEFT"
            onClick={handleRefazerAvaliacao}
          />
        </Show>
      </Card>
      {/*
        primaryColor='#F06F30'
        secondaryColor='#1F3A93'
        image={LegislacaoDefensiva}
        titulo={modulo?.nome}
        onClick={onClick}
        porcentagemConclusao={Math.round(modulo.progresso)}
      */}
    </div>
  )
}

type STATUS_CURSO =
  | 'MODULO_NAO_INICIADO'
  | 'MODULO_INICIADO'
  | 'AGUARDANDO_AVALIACAO'
  | 'REFAZER_AVALIACAO'
  | 'AGUARDANDO_VALIDACAO_FACIAL_FINAL'
  | 'AGUARDANDO_ENVIO_CERTIFICADO'
  | 'AGUARDANDO_LIBERACAO_CERTIFICADO'
  | 'CURSO_CONCLUIDO'
  | 'AGUARDANDO_CURSO_PRATICO'
  | ''

ModuloEmAndamento.getStatusCurso = (
  modulos: ModuloWithEstatisticaEstudo[],
  matricula: MatriculaModel
): { status: STATUS_CURSO; moduloAtual: ModuloWithEstatisticaEstudo | null } => {
  if (
    matricula &&
    !matricula.parceiroId &&
    !matricula.parceiroCursoPraticoId &&
    [
      STATUS_MATRICULA.AGUARDANDO_CONCLUSAO_MODULO_PRATICO,
      STATUS_MATRICULA.AGUARDANDO_MATRICULA_MODULO_PRATICO,
    ].includes(matricula.statusDetran)
  ) {
    return { moduloAtual: null, status: 'AGUARDANDO_CURSO_PRATICO' }
  }
  if (matricula && matricula.dataValidacaoFacialFinal && !matricula.permiteEmissaoCertificado) {
    return { moduloAtual: null, status: 'AGUARDANDO_LIBERACAO_CERTIFICADO' }
  }
  if (!modulos.length) return { moduloAtual: null, status: '' }
  const modulosIncompletos = modulos.filter((m) => !m.estatisticaEstudo?.moduloCompleto)
  if (!modulosIncompletos.length) {
    if (matricula?.statusDetran === STATUS_MATRICULA.AGUARDANDO_ENVIO) {
      return { moduloAtual: null, status: 'AGUARDANDO_ENVIO_CERTIFICADO' }
    }
    if (matricula && !matricula.dataValidacaoFacialFinal)
      return { moduloAtual: null, status: 'AGUARDANDO_VALIDACAO_FACIAL_FINAL' }
    return { moduloAtual: null, status: 'CURSO_CONCLUIDO' }
  }
  const moduloAtual = modulosIncompletos[0]
  const refazerAvaliacao =
    moduloAtual.estatisticaEstudo?.desempenhoAvaliacao &&
    moduloAtual.estatisticaEstudo?.desempenhoAvaliacao < 70
  const aguardandoAvaliacao =
    !refazerAvaliacao && moduloAtual.estatisticaEstudo?.percentualProgressoMaisAvancado === 100

  if (!moduloAtual.estatisticaEstudo?.percentualProgressoMaisAvancado)
    return { moduloAtual, status: 'MODULO_NAO_INICIADO' }
  if (aguardandoAvaliacao) return { moduloAtual, status: 'AGUARDANDO_AVALIACAO' }
  if (refazerAvaliacao) return { moduloAtual, status: 'REFAZER_AVALIACAO' }
  return { moduloAtual, status: 'MODULO_INICIADO' }
}
ModuloEmAndamento.Card = styled(Card)<{ color }>(
  (props) => `
  border-top: 8px solid ${props.color || '#2F80ED'};
  padding: 0 18px;
`
)
ModuloEmAndamento.Button = styled(Button)`
  width: 231px;
  height: 48px;
  margin: 28px auto;
  @media (max-width: 768px) {
    margin: 28px auto 11px;
  }
`
ModuloEmAndamento.Titulo = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-top: 28px;
  @media (max-width: 768px) {
    margin-top: 18px;
  }
`
ModuloEmAndamento.PorcentagemConclusao = styled.div`
  font-size: 14px;
  margin-top: 18px;
  @media (max-width: 768px) {
    margin-top: 11px;
  }
  display: flex;
  align-items: center;
`
ModuloEmAndamento.Status = styled.div`
  background: rgba(54, 255, 107, 0.19);
  border-radius: 4px;
  padding: 7px 19px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #27ae60;
  width: 110px;
`

const Avisos = ({ ...props }) => {
  const { formatDate, Card, Texto, Data } = Avisos
  const curso = useCursoAtual({ cursoId: props.cursoId })

  return (
    <Card titulo="Avisos" icone={iconeCirculoExclamacao} primaryColor="#F06F30" {...props}>
      {AVISOS(curso!).map((aviso, i, self) => (
        <TimelineItem key={aviso.id} omitLine={i === self.length - 1}>
          <div style={{ fontWeight: aviso.visualizado ? 'normal' : 450 }}>
            <Texto>{aviso.texto}</Texto>
          </div>
        </TimelineItem>
      ))}
    </Card>
  )
}
Avisos.formatDate = (date: string) => {
  return formatDistance(parseISO(date), new Date(), { addSuffix: true, locale: ptBR }).replace(
    /há/,
    'a'
  )
}
Avisos.Card = styled(CardMedio)``
Avisos.Data = styled.div`
  font-style: italic;
  font-size: 12px;
`
Avisos.Texto = styled.div`
  padding: 8px 0 28px 0;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 129%;
`

const Mensagens = () => {
  return (
    <CardNotificacao titulo="Mensagens" icone={iconeMensagem} primaryColor="#F06F30">
      {MENSAGENS.map((mensagem, i, a) => (
        <MensagemItem key={i} mensagem={mensagem} divider={i < a.length - 1} />
      ))}
    </CardNotificacao>
  )
}

const MensagemItem = ({ mensagem, divider }) => {
  return (
    <MensagemItemContainer borderBottom={divider}>
      <MensagemItemTitulo>{mensagem.titulo}</MensagemItemTitulo>
      <MensagemItemSubTitulo>
        {mensagem.tutor} - {mensagem.pastTime}
      </MensagemItemSubTitulo>
      <MensagemItemConteudo>{mensagem.conteudo}</MensagemItemConteudo>
    </MensagemItemContainer>
  )
}

const MensagemItemContainer = styled('div')<{ borderBottom }>(
  (props) => `
  border-bottom: ${props.borderBottom ? '1px solid #D9D9D9' : 'none'};
  margin-bottom: 18px;
`
)

const MensagemItemSubTitulo = styled('div')`
  font-size: 14px;
  color: #2d2d2d;
  margin-bottom: 3px;
`

const MensagemItemTitulo = styled(MensagemItemSubTitulo)`
  font-weight: 700;
  margin-bottom: 4px;
`

const MensagemItemConteudo = styled(MensagemItemSubTitulo)`
  color: #88898c;
`

const Calendario = () => {
  return <div>Calendario</div>
}

const AulaAoVivo = () => {
  return (
    <CardNotificacao titulo="Aula ao vivo" icone={videocam} primaryColor="#F06F30">
      {AULAS.map((aula) => (
        <AulaItem key={aula.titulo} aula={aula} />
      ))}
    </CardNotificacao>
  )
}

const AulaItem = ({ aula }) => {
  return (
    <AulaItemContainer>
      <div>{aula.horario}</div>
      <div>{aula.titulo}</div>
      <div>{aula.modulo}</div>
      <div>{aula.professsor}</div>
    </AulaItemContainer>
  )
}

const AulaItemContainer = styled('div')`
  margin-bottom: 10px;
`

const ForumDiscussoes = ({
  foruns,
  ...props
}: { foruns: ForumModel[] } & React.HTMLAttributes<HTMLDivElement>) => {
  const { cursoId } = useParams<HomeParams>()
  const history = useHistory()
  return (
    <CardNotificacao
      titulo="Fórum de discussões"
      icone={iconeForumQuestao}
      primaryColor="#F06F30"
      onClick={() => history.push(`/curso/${cursoId}/foruns`)}
      {...props}
    >
      {foruns.length ? <ListaForum foruns={foruns} /> : <EmptyForum />}
    </CardNotificacao>
  )
}

const ListaForum = ({ foruns }: { foruns: ForumModel[] }) => {
  return (
    <>
      {foruns.map((pergunta, i) => (
        <ForumItem key={i} forumItem={pergunta} />
      ))}
    </>
  )
}

const EmptyForum = () => {
  const { Layout } = EmptyForum
  return (
    <Layout>
      <div>
        <strong>Nenhuma discussão foi iniciada.</strong>
      </div>
      <div style={{ marginTop: 8 }}>Comece fazendo alguma pergunta.</div>
    </Layout>
  )
}
EmptyForum.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
`

const ForumItem = ({ forumItem }: { forumItem: ForumModel }) => {
  const history = useHistory()
  const { cursoId } = useParams<HomeParams>()
  const handleResponder = () => {
    history.push(`/curso/${cursoId}/foruns/${forumItem.id}`)
  }
  return (
    <ForumItemContainer>
      <ForumItemTitulo>{forumItem.titulo}</ForumItemTitulo>
      <ForumItemConteudo>{forumItem.texto}</ForumItemConteudo>
      <ForumItemFooter>
        <ForumItemPessoas usuarios={forumItem.forunsMensagens.map((f) => f.usuario)} />
        <ForumItemButton onClick={handleResponder} className="ion-activatable ripple-parent">
          <ForumItemButtonIcon>
            <IonIcon icon={iconeResposta} />
          </ForumItemButtonIcon>
          <IonRippleEffect type="unbounded" />
          <span>Responder</span>
        </ForumItemButton>
      </ForumItemFooter>
    </ForumItemContainer>
  )
}

const ForumItemPessoas = ({ usuarios }) => {
  const _usuarios = [...usuarios]
  const usuariosDestaque = _usuarios.slice(0, 3)

  return (
    <ForumItemPessoasContainer>
      {usuariosDestaque.map((usuario, i) => (
        <ForumItemAvatar
          key={i}
          usuario={usuario}
          circular
          tamanho={30}
          index={10 - i}
          marginLeft={i > 0 ? '-15px' : 0}
        />
      ))}
      <span style={{ marginLeft: '11px' }}>
        {usuarios.length > 3 ? `+${usuarios.length - 3}` : ''}
      </span>
    </ForumItemPessoasContainer>
  )
}

const ForumItemAvatar = styled(AppAvatar)<{ index; marginLeft }>(
  (props) => `
  z-index: ${props.index};
  margin-left: ${props.marginLeft};
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`
)

const ForumItemContainer = styled(Card)`
  margin: 0px 0px 18px 0px;
  padding: 18px;
`

const ForumItemPessoasContainer = styled('div')`
  color: #2d2d2d;
  font-size: 10px;
  display: flex;
  align-items: center;
`

const ForumItemButton = styled('div')`
  position: relative;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2d2d2d;
  font-size: 10px;
  justify-self: end;
`

const ForumItemButtonIcon = styled('span')`
  color: #ff9933;
  font-size: 1.5em;
`

const ForumItemFooter = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
`

const ForumItemTitulo = styled('div')`
  font-weight: 700;
  font-size: 16px;
  color: #2d2d2d;
  margin-bottom: 8px;
`

const ForumItemConteudo = styled('div')`
  font-size: 14px;
  color: #c4c4c4;
  min-height: 48px;
  margin-bottom: 18px;
`

const Certificado = ({ matricula }) => {
  const {
    Layout,
    Button,
    ContainerErro,
    checkCursoNecessitaProvaDetranCursoEspecializado,
    checkCursoNecessitaProvaDetranReciclagem,
    AgendaProvaDetranGo,
  } = Certificado
  const curso = useCursoAtual()
  const handleClickAgendarProvaDetranCursoEspecializado = () =>
    window.open('https://www.detran.pe.gov.br/prova-teorica-de-reciclagem')
  const handleClickAgendarProvaDetranReciclagem = () =>
    window.open('https://www.detran.pe.gov.br/prova-teorica-de-reciclagem')

  const [{ data, loading, error }] = useAxios<MatriculaModel>(
    `/matriculas/certificado?cursoId=${curso?.id}`
  )

  function handleClick() {
    if (process.env.NODE_ENV === 'production') {
      window.open(`https://app.newdriver.com.br/certificado/${data?.identificacaoCertificado}`)
      return
    }
    window.open(
      `${process.env.REACT_APP_API_URL}/certificados/render/${data?.identificacaoCertificado}`
    )
  }

  if (loading) {
    return <Layout style={{ textAlign: 'center' }}>Buscando dados do certificado!</Layout>
  }

  if (error) {
    return (
      <Layout style={{ marginTop: 36 }}>
        <div style={{ textAlign: 'center' }}>Não foi possível emitir o certificado!</div>
        <ContainerErro>
          <small>Motivo</small>
          <strong>
            {error.response?.data?.message ??
              'Erro não especificado, por favor entre em contato com o suporte'}
          </strong>
        </ContainerErro>
        <AgendaProvaDetranGo matricula={matricula} error={error} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Button label="Emitir Certificado" onClick={handleClick} />
      <Show
        condition={
          matricula?.uf === 'PE' && checkCursoNecessitaProvaDetranCursoEspecializado(matricula)
        }
      >
        <Button
          label="Agendar prova no DETRAN"
          fill="LINK"
          onClick={handleClickAgendarProvaDetranCursoEspecializado}
          style={{ marginTop: 18 }}
        />
      </Show>
      <Show
        condition={matricula?.uf === 'PE' && checkCursoNecessitaProvaDetranReciclagem(matricula)}
      >
        <Button
          label="Agendar prova de reciclagem no DETRAN"
          fill="LINK"
          onClick={handleClickAgendarProvaDetranReciclagem}
          style={{ marginTop: 18 }}
        />
      </Show>
    </Layout>
  )
}
Certificado.checkCursoNecessitaProvaDetranCursoEspecializado = (matricula) => {
  const cursosDeAtualizacao = [3, 4, 5, 6, 7]
  if (cursosDeAtualizacao.includes(Number(matricula.cursoId))) return true
  return false
}
Certificado.checkCursoNecessitaProvaDetranReciclagem = (matricula) => {
  const cursosDeAtualizacao = [1, 18]
  if (cursosDeAtualizacao.includes(Number(matricula.cursoId))) return true
  return false
}
Certificado.Layout = styled.div`
  margin: 58px 0;
`
Certificado.Button = styled(Button)`
  width: 231px;
  height: 48px;
  margin: 28px auto;
  @media (max-width: 768px) {
    margin: 28px auto 11px;
  }
`
Certificado.ContainerErro = styled.div`
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 36px;
  small {
    display: block;
    font-style: italic;
    margin-bottom: 8px;
  }
`
Certificado.AgendaProvaDetranGo = ({
  matricula,
  error,
}: {
  matricula: MatriculaModel
  error: AxiosError<any>
}) => {
  const handleClickAgendarProvaDetranGoCursoReciclagem = () =>
    window.open('https://goias.gov.br/detran/fazer-curso-de-reciclagem/')

  const errorResponse: string = error.response?.data?.message ?? error.response?.data ?? ''

  return (
    <Show
      condition={
        matricula?.uf === 'GO' &&
        Number(matricula.cursoId) === 1 &&
        errorResponse.toUpperCase().includes('PROVA NAO ENCONTRADA')
      }
    >
      <div>
        <p>Agende sua prova no DETRAN GO</p>
      </div>
      <Button
        label="Agendar prova"
        fill="LINK"
        onClick={handleClickAgendarProvaDetranGoCursoReciclagem}
        style={{ marginTop: 18 }}
      />
    </Show>
  )
}

function AguardandoEnvioCertificado({ matricula }: { matricula: MatriculaModel }) {
  const { Layout } = AguardandoEnvioCertificado
  const usuario = useSelector(getUsuarioLogado)
  const jivo = useOpenJivoChat()
  function handleOpenSuporte() {
    jivo.openJivoChat({
      customData: [
        { title: 'CPF', content: usuario.cpf },
        { title: 'Nome', content: usuario.nome },
        { title: 'Curso', content: matricula.curso?.nome },
        { title: 'CursoId', content: '' + matricula.cursoId },
      ],
    })
  }
  return (
    <Layout>
      <p>Seu certificado será registrado junto ao DETRAN e em breve estará disponível.</p>
      <p>
        Caso tenha urgência na emissão do certificado,{' '}
        <a onClick={handleOpenSuporte}>entre em contato com o suporte clicando aqui</a>
      </p>
    </Layout>
  )
}
AguardandoEnvioCertificado.Layout = styled.div`
  margin: 58px 0;
  padding: 0 24px;
  a {
    cursor: pointer;
    &:hover {
      filter: brightness(1.2);
    }
  }
`

export default Home
