import { Formik, Form, useFormik, useFormikContext, FormikProvider } from 'formik'
import { IonFooter, IonItemDivider } from '@ionic/react'
import { Plugins, CameraResultType } from '@capacitor/core'
import { useDispatch, useSelector } from 'react-redux'
import { useMedia } from 'use-media'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { TextField } from '../common/TextField'
import { getUsuarioLogado } from '../auth/AuthStore'
import { AppAvatar } from '../components/AppAvatar'
import { TituloPagina, Button, Modal } from '../components'
import { ReactComponent as BetaTester } from '../assets/icons/beta_tester.svg'
import PictureIcon from '../svgs/picture'
import CameraIcon from '../svgs/camera-outline'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { PerfilStore as Store, formValuesType, AvatarCrop } from './PerfilStore'
import deleteIcon from '../assets/icons/garbage-can-delete.svg'
import { Yup } from '../common/Yup'
import { useHistory } from 'react-router'

const { Camera } = Plugins

export const Perfil = () => {
  const { Layout, LayoutForm } = Perfil
  return (
    <Store.Provider>
      <FormWrapper>
        <MobileInnerNav />
        <Layout>
          <LayoutForm>
            <Heading />
            <InfoPessoal>
              <Avatar />
              <NomeDePreferencia className="nome-de-preferencia" />
            </InfoPessoal>
            <InfoContato>
              <Email className="email" />
              <Telefone className="telefone" />
            </InfoContato>
          </LayoutForm>
          <Seguranca>
            <Store.GetState>
              {(state) => (state.alterandoSenha ? <AlterarSenhaModal /> : <></>)}
            </Store.GetState>
          </Seguranca>
          <BetaTesterOptIn />
        </Layout>
      </FormWrapper>
      <ModalSelecaoAvatar />
    </Store.Provider>
  )
}
Perfil.Layout = styled.div`
  .Toastify__toast-container {
    z-index: 3000;
  }
`
Perfil.LayoutForm = styled.div`
  padding: 0 36px;
  @media (max-width: 769px) {
    padding: 0 18px;
  }
  .avatar {
    padding-right: 18px;
    justify-content: flex-start;
  }
  .nome-de-preferencia {
    width: 100%;
  }
  .email {
    width: 100%;
  }
  .telefone {
    align-self: flex-start;
    width: 100%;
  }

  .avatar,
  .nome-de-preferencia,
  .email,
  .telefone {
    .input {
      margin-top: 8px;
      box-shadow: none;
      border-radius: 4px;
    }
  }
`
const InfoPessoal = ({ children }) => {
  const { Layout } = InfoPessoal
  return <Layout>{children && children}</Layout>
}
InfoPessoal.Layout = styled('div')`
  margin-top: 19px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const InfoContato = ({ children }) => {
  const { Layout } = InfoContato
  return (
    <Layout>
      <span className="contato-heading">Contato</span>
      <div className="contato-fields">{children && children}</div>
    </Layout>
  )
}
InfoContato.Layout = styled('div')`
  padding-top: 18px;
  .contato-heading {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 14px;
  }
  .contato-fields {
    gap: 18px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    @media (max-width: 769px) {
      flex-direction: column;
      gap: 0;
    }
    justify-content: space-between;
  }
`

const FormWrapper = ({ children }) => {
  const perfil = useSelector(getUsuarioLogado)
  const dispatch = useDispatch()
  const storeDispatch = Store.useDispatch()
  const { formValues } = Store.useState()
  const { Layout } = FormWrapper
  const { avatar, nome, telefone, email, cpf } = perfil

  useEffect(() => {
    storeDispatch(
      Store.actions.updateFormValues({
        avatar,
        nome,
        telefone,
        email,
        cpf,
      })
    )
  }, [perfil])

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      nome: Yup.string().required(),
      email: Yup.string().required().email(),
      telefone: (Yup as any).string().required().mascaraCompleta('Telefone inválido'),
    }),
    onSubmit: (values, x) => {
      storeDispatch(Store.thunks.updateUsuario(values, dispatch))
    },
  })

  return (
    <FormikProvider value={formik}>
      <Layout>{children}</Layout>
    </FormikProvider>
  )
}
FormWrapper.Layout = styled('div')`
  input {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: none;
  }
`

const Avatar = () => {
  const storeDispatch = Store.useDispatch()
  const perfil = useSelector(getUsuarioLogado)
  const { formValues, edicaoPerfil, croppedAvatar } = Store.useState()
  const { Layout } = Avatar

  useEffect(() => {
    if (perfil.avatar) {
      updateAvatar(perfil.avatar)
    }
  }, [perfil.avatar])

  const resetAvatar = () => storeDispatch(Store.actions.resetAvatar())
  const resetError = () => storeDispatch(Store.actions.resetError())
  const setArquivoAvatar = (arquivoAvatar: string) =>
    storeDispatch(Store.actions.setArquivoAvatar(arquivoAvatar))
  const resetArquivoAvatar = () => storeDispatch(Store.actions.resetArquivoAvatar())
  const resetCroppedAvatar = () => storeDispatch(Store.actions.resetCroppedAvatar())
  const updateAvatar = (avatar: string) => storeDispatch(Store.actions.updateAvatar(avatar))

  const resetarAvatar = () => {
    resetAvatar()
    resetArquivoAvatar()
    resetCroppedAvatar()
  }

  const carregarNovaFoto = async (e) => {
    resetarAvatar()
    storeDispatch(Store.actions.ativarEdicaoAvatar())
    storeDispatch(Store.actions.resetError())
    const { files } = e.target
    if (files?.length) {
      const file = files[0]
      if (!file.type.includes('image')) {
        updateAvatar(perfil.avatar)
        resetError()
        return
      }
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.addEventListener('load', async (e) => {
        const file = e?.target?.result
        setArquivoAvatar(file as string)
      })
    }
  }

  const avatarExistente = edicaoPerfil && (formValues.avatar || croppedAvatar)

  return (
    <Layout>
      <div className="avatar-container">
        {avatarExistente && <div className="edit-avatar-overlay" onClick={resetarAvatar} />}
        <AppAvatar imagemPreview={croppedAvatar} tamanho={70} circular usuario={perfil} />
        {edicaoPerfil && (
          <Button
            onClick={carregarNovaFoto}
            style={{ width: '75px', padding: 0 }}
            fill="LINK"
            label="alterar foto"
          />
        )}
      </div>
    </Layout>
  )
}
Avatar.Layout = styled('div')`
  display: grid;
  justify-content: center;
  height: 92px;
  margin-right: 18px;
  .avatar-container {
    .edit-avatar-overlay {
      :hover {
        cursor: pointer;
        background-color: #2d2d2d70;
        background-image: url(${deleteIcon});
        background-repeat: no-repeat;
        background-position: center;
      }
      z-index: 10;
      width: 70px;
      height: 70px;
      position: fixed;
      border-radius: 50%;
    }
  }
`
const ModalSelecaoAvatar = () => {
  const storeDispatch = Store.useDispatch()
  const { error, edicaoAvatar, arquivoAvatar, avatarCrop } = Store.useState()
  const [imageRef, setImageRef] = useState({} as any)

  const setAvatarCrop = (avatarCrop: Partial<AvatarCrop>) =>
    storeDispatch(Store.actions.setAvatarCrop(avatarCrop))
  const setCroppedAvatar = (croppedAvatar: string) =>
    storeDispatch(Store.actions.setCroppedAvatar(croppedAvatar))
  const desativarEdicaoAvatar = () => storeDispatch(Store.actions.desativarEdicaoAvatar())

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return canvas.toDataURL('image/jpeg')
  }

  const finalizarCrop = () => {
    if (imageRef && avatarCrop.width && avatarCrop.height) {
      setCroppedAvatar(getCroppedImg(imageRef, avatarCrop))
      storeDispatch(Store.actions.updateAvatar(getCroppedImg(imageRef, avatarCrop)))
      desativarEdicaoAvatar()
    }
  }

  const cancelarCrop = () => {
    desativarEdicaoAvatar()
  }

  const { Layout } = ModalSelecaoAvatar
  return (
    <Modal title="Editar foto" isOpen={edicaoAvatar} closeModal={() => desativarEdicaoAvatar()}>
      <Layout>
        {arquivoAvatar && !error ? (
          <div className="crop-container">
            <ReactCrop
              src={arquivoAvatar}
              crop={avatarCrop}
              circularCrop
              onChange={(crop, percentCrop) => setAvatarCrop(crop)}
              onImageLoaded={(image) => setImageRef(image)}
            />
            <div className="buttons-group">
              <Button label="Cancelar" fill="OUTLINE" onClick={cancelarCrop} />
              <Button label="Salvar" fill="SOLID" onClick={finalizarCrop} />
            </div>
          </div>
        ) : (
          <div>{error ? <ErroDeUploadDeArquivo /> : <UploadDeAvatar />}</div>
        )}
      </Layout>
    </Modal>
  )
}
ModalSelecaoAvatar.Layout = styled('div')`
  .crop-container {
    .buttons-group {
      margin-top: 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  padding: 0 18px 18px 18px;
`

const UploadDeAvatar = () => {
  const perfil = useSelector(getUsuarioLogado)
  const storeDispatch = Store.useDispatch()

  const updateAvatar = (avatar: string) => storeDispatch(Store.actions.updateAvatar(avatar))
  const setArquivoAvatar = (arquivoAvatar: string) =>
    storeDispatch(Store.actions.setArquivoAvatar(arquivoAvatar))
  const setError = (error: string) => storeDispatch(Store.actions.setError(error))
  const resetError = () => storeDispatch(Store.actions.resetError())

  const carregarNovaFoto = async (e) => {
    resetError()
    const { files } = e.target
    if (files?.length) {
      const file = files[0]
      if (!file.type.includes('image')) {
        updateAvatar(perfil.avatar)
        setError('Tipo de arquivo inválido')
        return
      }
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.addEventListener('load', async (e) => {
        const file = e?.target?.result
        setArquivoAvatar(file as string)
      })
    }
  }

  const carregarCamera = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
      })
      setArquivoAvatar(image.dataUrl as string)
    } catch (error: any) {
      setError('Câmera não encontrada')
    }
  }

  const choosePictureFromDevice = () => document.getElementById('file-input')?.click()
  const { Layout } = UploadDeAvatar
  return (
    <Layout>
      <p>Escolha como deseja carregar uma foto para o perfil:</p>
      <div className="buttons-group">
        <Button
          className="upload-selection-button camera"
          svg={CameraIcon}
          label="Tirar foto com a câmera"
          fill="OUTLINE"
          color="#000"
          onClick={carregarCamera}
        />
        <Button
          className="upload-selection-button picture"
          svg={PictureIcon}
          label="Selecionar uma foto da biblioteca"
          fill="OUTLINE"
          color="#000"
          onClick={choosePictureFromDevice}
        />
      </div>
      <input
        style={{ display: 'none' }}
        id="file-input"
        type="file"
        accept="image/*"
        onChange={carregarNovaFoto}
      />
    </Layout>
  )
}
UploadDeAvatar.Layout = styled('div')`
  .buttons-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
    justify-items: center;

    .upload-selection-button {
      height: 186px;
      width: 186px;
      display: flex;
      flex-direction: column-reverse;
      --background-color-hover: #f06f30;
      --color-hover: #fff;
      .icone {
        margin-bottom: 22px;
        margin-left: 0;
      }
      :hover {
        border-color: #f06f30;
        .icone svg path {
          fill: #fff;
        }
      }
    }
    @media (max-width: 510px) {
      grid-template-columns: 1fr;
      .upload-selection-button {
        width: 95%;
        height: 120px;
      }
    }
  }
`

const ErroDeUploadDeArquivo = () => {
  const perfil = useSelector(getUsuarioLogado)
  const storeDispatch = Store.useDispatch()
  const { error } = Store.useState()

  const resetError = () => storeDispatch(Store.actions.resetError())
  const setArquivoAvatar = (arquivoAvatar: string) =>
    storeDispatch(Store.actions.setArquivoAvatar(arquivoAvatar))
  const updateAvatar = (avatar: string) => storeDispatch(Store.actions.updateAvatar(avatar))

  const carregarNovaFoto = async (e) => {
    storeDispatch(Store.actions.resetError())
    const { files } = e.target
    setArquivoAvatar('')
    if (files?.length) {
      const file = files[0]
      if (!file.type.includes('image')) {
        updateAvatar(perfil.avatar)
        resetError()
        return
      }
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.addEventListener('load', async (e) => {
        const file = e?.target?.result
        setArquivoAvatar(file as string)
      })
    }
  }

  const { Layout } = ErroDeUploadDeArquivo
  return (
    <Layout>
      <p className="error-text">{error}</p>
      <div className="error-button">
        <Button label="Selecionar nova foto" fill="SOLID" onClick={carregarNovaFoto} />
      </div>
    </Layout>
  )
}
ErroDeUploadDeArquivo.Layout = styled('div')`
  .error-text {
    color: red;
    margin-bottom: 20px;
  }
`

const NomeDePreferencia = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    edicaoPerfil: edicaoAtivada,
    formValues: { nome },
  } = Store.useState()
  const { Layout } = NomeDePreferencia
  return (
    <Layout {...props}>
      {edicaoAtivada ? (
        <TextField
          className={`${props.className || ''} input`}
          label="Nome de Preferência:"
          name="nome"
          type="text"
          placeholder="Nome"
        />
      ) : (
        <ProfileDataItem label="Nome de Preferência:" value={nome} />
      )}
    </Layout>
  )
}
NomeDePreferencia.Layout = styled('div')`
  align-self: center;
`

const Email = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { edicaoPerfil: edicaoAtivada, formValues } = Store.useState()
  const { Layout } = Email
  return (
    <Layout {...props}>
      {edicaoAtivada ? (
        <TextField
          className={`${props.className || ''} input`}
          label="Email:"
          name="email"
          type="text"
          placeholder="E-mail"
        />
      ) : (
        <ProfileDataItem label="E-mail:" value={formValues.email} />
      )}
    </Layout>
  )
}
Email.Layout = styled('div')`
  @media (min-width: 769px) {
    padding-right: 9px;
  }
`

const Telefone = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { values } = useFormikContext()
  const { edicaoPerfil: edicaoAtivada, formValues } = Store.useState()
  const { Layout } = Telefone
  return (
    <Layout {...props}>
      {edicaoAtivada ? (
        <TextField
          className={`${props.className || ''} input`}
          mask="telefone"
          label="Telefone:"
          name="telefone"
          type="tel"
          placeholder="Telefone"
        />
      ) : (
        <ProfileDataItem label="Telefone:" value={formValues.telefone} />
      )}
    </Layout>
  )
}
Telefone.Layout = styled('div')``

const Seguranca = ({ children }) => {
  const dispatchStore = Store.useDispatch()

  const abrirModal = () => dispatchStore(Store.actions.ativarAlteracaoSenha())

  const { Layout } = Seguranca
  return (
    <Layout>
      <h3>Segurança</h3>
      <Button onClick={abrirModal} color="#007AFF" fill="OUTLINE" label="Alterar Senha" />
      {children}
    </Layout>
  )
}
Seguranca.Layout = styled('div')`
  padding: 0 36px;
  @media (max-width: 769px) {
    padding: 0 18px;
  }
  h3 {
    font-size: 14px;
    font-weight: 700;
  }
`
Seguranca.Modal = styled(Modal)``
Seguranca.Footer = styled(IonFooter)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const AlterarSenhaModal = () => {
  const dispatchStore = Store.useDispatch()
  const { alterandoSenha, loading } = Store.useState()

  const fecharModal = () => dispatchStore(Store.actions.desativarAlteracaoSenha())

  const formik = useFormik({
    initialValues: {
      senhaAtual: '',
      novaSenha: '',
      confirmacaoNovaSenha: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.senhaAtual) errors.senhaAtual = 'Senha Atual é obrigatória'
      if (!values.novaSenha) errors.novaSenha = 'Nova Senha é obrigatória'
      if (!values.confirmacaoNovaSenha)
        errors.confirmacaoNovaSenha = 'Confirmação da nova senha é obrigatória'
      if (values.novaSenha.length < 6 || values.senhaAtual.length < 6)
        errors.novaSenha = 'Sua senha deve ter no mínimo 6 caracteres'
      if (values.novaSenha !== values.confirmacaoNovaSenha)
        errors.confirmacaoNovaSenha = 'Confirmação de senha incompatível'
      return errors
    },
    onSubmit: async (values, { resetForm }) => {
      const { novaSenha, senhaAtual } = values
      dispatchStore(Store.thunks.updateSenhaUsuario({ novaSenha, senhaAtual }))
    },
  })

  const { Content } = AlterarSenhaModal

  const handleAlterarSenha = async () => await formik.submitForm()

  return (
    <Modal
      className="alterar-senha-modal"
      title="Alterar Senha"
      isOpen={alterandoSenha}
      closeModal={fecharModal}
    >
      <Content>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              label="Senha atual:"
              name="senhaAtual"
              type="password"
              placeholder="Informe sua senha atual"
            />
            <TextField
              label="Nova senha:"
              name="novaSenha"
              type="password"
              placeholder="Informe a nova senha"
            />
            <TextField
              label="Repita a nova senha:"
              name="confirmacaoNovaSenha"
              type="password"
              placeholder="Confirme sua nova senha"
            />
          </Form>
          <div className="button-container">
            <Button loading={loading} onClick={handleAlterarSenha} label="Alterar" fill="CLEAR" />
          </div>
        </FormikProvider>
      </Content>
    </Modal>
  )
}
AlterarSenhaModal.Content = styled('div')`
  padding-top: 18px;
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ProfileDataItem = ({ label, value }: { label: string; value: string }) => {
  const { Layout, Label } = ProfileDataItem
  return (
    <Layout>
      <Label>{label}</Label>
      <span className="value">{value}</span>
    </Layout>
  )
}
ProfileDataItem.Layout = styled('div')`
  margin-bottom: 28px;
  margin-top: 0;
`
ProfileDataItem.Label = styled('div')`
  margin-bottom: 8px;
`

const Heading = () => {
  const { Layout } = Heading
  return (
    <Layout>
      <div>
        <TituloPagina>Perfil</TituloPagina>
      </div>
      <div>
        <HeadingButtonContainer />
      </div>
    </Layout>
  )
}
const HeadingButtonContainer = () => {
  const { helpers } = HeadingButtonContainer
  const { avatar } = useSelector(getUsuarioLogado)
  const isWide = useMedia({ minWidth: '769px' })
  const storeDispatch = Store.useDispatch()
  const dispatch = useDispatch()
  const { edicaoPerfil: edicaoAtivada, formValues, loading } = Store.useState()
  const { submitForm, values } = useFormikContext()
  const ativarEdicao = () => storeDispatch(Store.actions.ativarEdicaoPerfil())
  const actionFinalizarEdicao = () => storeDispatch(Store.actions.finalizarEdicao())
  const resetAvatar = () => storeDispatch(Store.actions.resetAvatar())
  const resetArquivoAvatar = () => storeDispatch(Store.actions.resetArquivoAvatar())
  const resetCroppedAvatar = () => storeDispatch(Store.actions.resetCroppedAvatar())

  const salvarDadosUsuario = () => {
    submitForm()
  }

  const finalizarEdicao = () => {
    resetAvatar()
    resetArquivoAvatar()
    resetCroppedAvatar()
    actionFinalizarEdicao()
  }

  const alteracoesPerfil = React.useMemo(
    () => helpers.checkAlteracoesPerfil(avatar, formValues, values),
    [formValues, values]
  )

  if (!isWide) return null
  return (
    <>
      {edicaoAtivada ? (
        <div style={{ display: 'flex', justifyContent: 'flex-row' }}>
          <Button
            style={{ marginRight: '8px' }}
            fill="OUTLINE"
            label="Cancelar"
            onClick={finalizarEdicao}
          />
          <Button
            disabled={!alteracoesPerfil}
            onClick={salvarDadosUsuario}
            label="Salvar Alterações"
          />
        </div>
      ) : (
        <Button loading={loading} label="Editar Perfil" onClick={ativarEdicao} />
      )}
    </>
  )
}
HeadingButtonContainer.helpers = (() => {
  function checkAlteracoesPerfil(avatar, formValues, values) {
    if (avatar !== formValues.avatar) return true
    if (JSON.stringify(formValues) !== JSON.stringify(values)) return true
    return false
  }
  return {
    checkAlteracoesPerfil,
  }
})()
Heading.Layout = styled('div')`
  display: flex;
  justify-content: space-between;

  @media (max-width: 769px) {
    height: 54px;
    flex-direction: column-reverse;
  }
`

const MobileInnerNav = () => {
  const history = useHistory()
  const { edicaoPerfil: edicaoAtivada } = Store.useState()
  const storeDispatch = Store.useDispatch()
  const dispatch = Store.useDispatch()
  const ativarEdicao = () => dispatch(Store.actions.ativarEdicaoPerfil())
  const finalizarEdicao = () => dispatch(Store.actions.finalizarEdicao())
  const { submitForm, values } = useFormikContext()

  const salvar = () => {
    submitForm()
  }

  const voltar = () => {
    history.goBack()
  }

  const { Layout, Divider, ButtonsContainer } = MobileInnerNav
  return (
    <Layout>
      <ButtonsContainer>
        <Button fill="LINK" onClick={voltar} label="Voltar" />
      </ButtonsContainer>
      {edicaoAtivada ? (
        <ButtonsContainer>
          <Button fill="LINK" onClick={finalizarEdicao} label="Cancelar" />
          <Button fill="LINK" onClick={salvar} label="Salvar" />
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          <Button onClick={ativarEdicao} label="Editar Perfil" fill="LINK" />
        </ButtonsContainer>
      )}
      <Divider mode="md" />
    </Layout>
  )
}
MobileInnerNav.Layout = styled('div')`
  @media (min-width: 769px) {
    display: none;
  }
  width: 100%;
  .buttons-container {
    padding: 0 18px;
    button {
      width: unset;
      padding: 0;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
MobileInnerNav.ButtonsContainer = styled('div')`
  padding: 4px 28px;
  button {
    width: unset;
    padding: 0;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
MobileInnerNav.Divider = styled(IonItemDivider)`
  min-height: 10px;
`

const BetaTesterOptIn = () => {
  const { betaTester } = useSelector(getUsuarioLogado)
  enum ModalStatus {
    Fechado,
    Participar,
    SaberMais,
  }
  const [modalStatus, setModalStatus] = useState(ModalStatus.Fechado)
  const dispatch = useDispatch()
  const dispatchStore = Store.useDispatch()
  const { formValues } = Store.useState()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.email) errors.email = 'Preencha o e-mail'
      if (values.email !== formValues.email)
        errors.email = 'E-mail não corresponde com o e-mail da sua conta'
      return errors
    },
    onSubmit: async (values) => {
      dispatchStore(Store.thunks.ativarBetaTesting(values.email, dispatch))
    },
  })

  const fecharModal = () => setModalStatus(ModalStatus.Fechado)
  const handleSubmit = () => {
    formik.submitForm()
    fecharModal()
  }

  const { Layout, Modal, ModalConteudoSaibaMais } = BetaTesterOptIn

  const modalAberto = modalStatus !== ModalStatus.Fechado
  const tituloModal = modalStatus === ModalStatus.Participar ? 'E-mail' : 'Driver tester'
  if (betaTester) return null
  return (
    <Layout>
      <Modal isOpen={modalAberto} closeModal={fecharModal} title={tituloModal}>
        {modalStatus === ModalStatus.Participar ? (
          <FormikProvider value={formik}>
            <div>
              <TextField className="input-email" type="e-mail" label="" name="email" />
            </div>
            <div className="button-container">
              <Button onClick={handleSubmit} label="Participar" fill="LINK" />
            </div>
          </FormikProvider>
        ) : (
          <ModalConteudoSaibaMais />
        )}
      </Modal>
      <div className="icon-container">
        <BetaTester />
      </div>
      <div className="text-container">
        <h1>Seja um beta tester</h1>
        <p>Você terá acesso aos nossos lançamentos antes de lançá-los para todo o público.</p>
        <div className="buttons-container">
          <Button onClick={() => setModalStatus(ModalStatus.Participar)} label="Participar" />
          <Button
            onClick={() => setModalStatus(ModalStatus.SaberMais)}
            fill="OUTLINE"
            label="Saber mais"
          />
        </div>
      </div>
    </Layout>
  )
}
BetaTesterOptIn.Layout = styled('div')`
  margin: 98px 0 29px 0;
  width: 100%;
  background-color: #f2f2f2;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(170px, 4fr);
  grid-gap: 4vw;

  @media (max-width: 769px) {
    margin-top: 78px;
  }

  .icon-container {
    position: relative;
    svg {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .text-container {
    h1 {
      font-size: 24px;
      margin-top: 18px;
      margin-bottom: 0;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      margin-top: 8px;
      margin-right: 18px;
    }
    padding-bottom: 20px;
  }
  .buttons-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    @media (max-width: 485px) {
      flex-direction: column;
    }
  }
`
BetaTesterOptIn.Modal = styled(Modal)`
  @media (min-width: 769px) {
    max-width: 668px;
  }

  .input-email {
    margin-top: 8px;
    box-shadow: none;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
`
BetaTesterOptIn.ModalConteudoSaibaMais = () => {
  return (
    <div>
      <p>Desfrute das novidades da New Driver em primeira mão!</p>
      <p>
        Você é um usuário importante para nós e sua opinião é muito relevante para a evolução do
        nosso aplicativo.
      </p>
      <span>Os Drivers tester são nossos usuários que nos ajudam com:</span>
      <span>&bull; Feedbacks úteis e sugestões dos pontos que podem ser melhorados;</span>
      <br />
      <br />

      <p>O que você ganha com isso?</p>
      <p>&bull; Acesso a funcionalidades novas em primeira mão</p>
      <p>&bull; Participação no desenvolvimento do app</p>
      <p>&bull; Tenha suas sugestões atendidas com mais agilidade</p>

      <p>
        Se você se identifica com os itens acima e está pronto para começar nessa, cadastre seu
        e-mail!
      </p>
    </div>
  )
}
