import React from 'react'
import { IonSlide, IonSlides } from '@ionic/react'
import { ModalTutorial } from '../components'
import styled from '@emotion/styled'
import Svgs from '../svgs'
import { GlobalStore } from '../GlobalStore'
import { getUsuarioLogado } from '../auth/AuthStore'
import { useSelector } from 'react-redux'
import imgIniciarModulo from '../assets/imagens/iniciar-modulo.png'
import { useHistory, useParams } from 'react-router'
import { useCursoAtual } from '../cursos/CursosStore'

interface LayoutParams {
  cursoId: string
}

export const Tutorial = () => {
  const { Modal, Layout, SlideRight, SlideLeft } = Tutorial
  const ref = React.useRef<any>()
  const globalDispatch = GlobalStore.useDispatch()
  const handleEnd = () => {
    globalDispatch(GlobalStore.actions.endTutorial('telaInicial'))
  }
  return (
    <Modal onEnd={handleEnd}>
      <Layout>
        <SlideLeft onClick={() => ref.current.slidePrev()}>
          <Svgs.ChevronLeft color="#333" />
        </SlideLeft>
        <IonSlides
          ref={ref}
          pager={true}
          options={{ initialSlide: 0, height: '100%' }}
          style={{ maxWidth: 'calc(100vw - 24px)' }}
        >
          <IonSlide>
            <VerifiqueOsDados />
          </IonSlide>
          <IonSlide>
            <ComeceAEstudar />
          </IonSlide>
          <IonSlide style={{ height: '100%' }}>
            <TempoEstudo />
          </IonSlide>
          <IonSlide>
            <ValidacaoFacial />
          </IonSlide>
          <IonSlide>
            <ExerciciosModulos />
          </IonSlide>
          <IonSlide>
            <AjudaTutores />
          </IonSlide>
          <IonSlide>
            <ProgressoEstudo />
          </IonSlide>
          <IonSlide>
            <AgendamentoProva />
          </IonSlide>
        </IonSlides>
        <SlideRight onClick={() => ref.current.slideNext()}>
          <Svgs.ChevronLeft style={{ transform: 'rotate(180deg)' }} color="#333" />
        </SlideRight>
      </Layout>
    </Modal>
  )
}
Tutorial.Modal = styled(ModalTutorial)`
  max-width: 777px;
  @media (max-width: 801px) {
    max-width: calc(100vw - 24px);
  }
`
Tutorial.Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`
Tutorial.SlideButton = styled.div`
  min-width: 30px;
  min-height: 30px;
  background: #f2f2f2;
  border-radius: 4px;
  display: grid;
  place-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`
Tutorial.SlideRight = styled(Tutorial.SlideButton)``
Tutorial.SlideLeft = styled(Tutorial.SlideButton)``

const VerifiqueOsDados = () => {
  const history = useHistory()
  const perfil = useSelector(getUsuarioLogado)
  const { cursoId } = useParams<LayoutParams>()
  const globalDispatch = GlobalStore.useDispatch()

  const handleClick = () => {
    globalDispatch(GlobalStore.actions.endTutorial('telaInicial'))

    history.push(`/curso/${cursoId}/perfil`)
  }

  return (
    <Container>
      <Titulo>Dados Cadastrais</Titulo>
      <Content style={{ marginTop: '50px' }}>
        <div>
          <Svgs.UsersFilled />
        </div>
        Olá <strong>{perfil.nome}</strong> <br />
        Seja bem vindo a plataforma New Driver. <br />
        Gostaríamos de confirmar seus <strong>dados cadastrais</strong>: <br />
        <List>
          <li>
            Nome <strong>{perfil.nome}</strong>
          </li>
          <li>
            E-mail <strong>{perfil.email}</strong>
          </li>
          <li>
            Telefone <strong>{perfil.telefone}</strong>
          </li>
        </List>
        Caso seus dados estejam errados, você pode{' '}
        <Anchor onClick={handleClick}>alterá-los aqui</Anchor>. <br />
        Essa atualização é muito importante e nos permite te atender de maneira mais eficiente. Se
        tiver qualquer dúvida estamos a disposição.
      </Content>
    </Container>
  )
}
const ComeceAEstudar = () => {
  return (
    <Container>
      <Titulo>Inicie seus estudos</Titulo>
      <Content style={{ marginTop: 24 }}>
        Para começar a estudar você deve clicar no botão <strong>Iniciar</strong>, apresentado
        dentro dos módulos.
      </Content>
      <Content style={{ marginTop: 48 }}>
        <img style={{ pointerEvents: 'none' }} src={imgIniciarModulo} alt="" />
      </Content>
    </Container>
  )
}
const TempoEstudo = () => {
  const curso = useCursoAtual()
  return (
    <Container>
      <Titulo>Tempo de estudo</Titulo>
      <Content style={{ marginTop: 75 }}>
        <div>
          <Svgs.GraduationCap />
        </div>
        Você deve estudar no mínimo{' '}
        <strong>{Math.ceil((curso?.cargaHoraria ?? 0) / 8)} dias</strong> para conseguir concluir os
        módulos do curso.
      </Content>
      <Content>
        <div>
          <Svgs.ClockFilled />
        </div>
        Você pode estudar por no máximo <strong>8h</strong> por dia e por <strong>4h</strong>{' '}
        consecutivas.
      </Content>
    </Container>
  )
}
const ValidacaoFacial = () => {
  return (
    <Container>
      <Titulo>Validação facial</Titulo>
      <Content>
        <div>
          <Svgs.UserBadge />
        </div>
        Todo o curso conta com a validação facial de acordo com a portaria <strong>730</strong>.{' '}
        <br />A validação facial acontece ao início e término do curso e durante o estudo dos
        módulos.
      </Content>
    </Container>
  )
}
const ExerciciosModulos = () => {
  return (
    <Container>
      <Titulo>Exercícios e avaliação</Titulo>
      <Content>
        <div>
          <Svgs.GraduationCap />
        </div>
        Cada página do módulo é composta por um conteúdo e <strong>exercícios</strong> relacionados
        ao módulo. Ao <strong>final do Módulo</strong> é necessário responder uma avaliação de{' '}
        <strong>15 questões</strong>.
      </Content>
    </Container>
  )
}
const AjudaTutores = () => {
  return (
    <Container>
      <Titulo>Ajuda dos tutores</Titulo>
      <Content>
        <div>
          <Svgs.UserBadge />
        </div>
        Em <strong>tutoria</strong> você fala com nossa equipe de tutores e tira todas as dúvidas
        que tiver ao longo do estudo.
        <br />
        Você também pode acionar o <strong>suporte</strong> sempre que precisar.
      </Content>
    </Container>
  )
}
const ProgressoEstudo = () => {
  return (
    <Container>
      <Titulo>Progresso de estudo</Titulo>
      <Content>
        <div>
          <Svgs.GraphBars />
        </div>
        Em <strong>progresso</strong> você encontra todo o seu desempenho durante o curso. Neste
        local você consegue visualizar quanto tempo já estudou, quantos exercícios e módulos já
        concluiu.
      </Content>
    </Container>
  )
}
const AgendamentoProva = () => {
  return (
    <Container>
      <Titulo>Agendamento da prova</Titulo>
      <Content>
        <div>
          <Svgs.ClockFilled />
        </div>
        Após concluir todos os módulos, você deve realizar o <strong>agendamento da prova</strong>{' '}
        junto ao Detran de seu estado.
      </Content>
    </Container>
  )
}

const Container = styled.div`
  color: #333333;
  font-size: 14px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 50vh;
  max-height: 90vh;
  @media (max-width: 768px) {
    max-width: calc(90vw - 48px);
  }
`
const Titulo = styled.div`
  margin-top: 38px;
  font-weight: bold;
`
const Content = styled.div`
  margin-top: 125px;
  line-height: 2em;
`
const List = styled.ol`
  list-style: disc;
  text-align: left;
  margin-left: 90px;

  li::marker {
    color: black;
    font-size: 1.1em;
  }

  @media only screen and (max-width: 768px) {
  /* For mobile phones: */
    margin-left: 0px;
  }
}
`
const Anchor = styled.a`
  cursor: pointer;
`
